import { HttpClient } from 'aurelia-fetch-client';
import { Router } from 'aurelia-router';
import { CustomLogger, EntityDetailViewModelBase, EnumerationType, EnumerationTypeService, ServiceBase } from 'digiwall-lib';
import { autoinject, BindingEngine } from 'aurelia-framework';
import { DialogController } from 'aurelia-dialog';
import { Zeus } from 'generated';
import * as Constants from '../constants';
import { TrayTemplateDetail } from 'tray-templates/tray-template-detail';
import { FilterQueryOp, Predicate } from 'breeze-client';
import { DataFormat } from 'select2';
import * as MSConstants from 'app-modules/mobile-screens/constants';
import { computedFrom } from 'aurelia-binding';
import { Utils } from 'utils/utils';

@autoinject
export class LocationDetailFromMask extends EntityDetailViewModelBase<Zeus.Web.Model.Location> {
  public ressourceName: string = Constants.EntityTypeNames.Location;
  private storageTypeId: number;
  private locationVolumeService: ServiceBase<Zeus.Web.Model.LocationVolume>;
  private locationService: ServiceBase<Zeus.Web.Model.Location>;
  private storageService: ServiceBase<Zeus.Web.Model.Storage>;
  private trayTemplateService: ServiceBase<Zeus.Web.Model.TrayTemplate>;
  public applicationParameterService: ServiceBase<Zeus.Web.Model.ApplicationParameters>;
  private storageVolumeService: ServiceBase<Zeus.Web.Model.StorageVolume>;
  private mask: string;
  private storageUnitWidth;
  private storageUnitLength;
  private storageUnitHeight;
  public foreignEntityId;
  public storage: Zeus.Web.Model.Storage;
  public trayTemplate: DataFormat;
  public trayTemplateIsSelected: boolean = false;
  private previewResults;
  private locationVolumes: Zeus.Web.Model.LocationVolume[];
  private previewBusy = false;
  private creationBusy = false;
  private deletionBusy = false;
  public isDeletion = false;
  public isFromTemplate = false;
  private articleRotationLevelTypeService: EnumerationTypeService;
  private dedicatedToRotationLevel: EnumerationType;
  private dedicatedToRotationLevelId: number;
  private moreThanOneArticleAllowed: boolean = false;
  private notAvailableForPicking: boolean = false;
  private notAvailableForInput: boolean = false;

  private Constants: any = Constants;

  constructor(router: Router, logger: CustomLogger, private dialogController: DialogController, private httpClient: HttpClient, private bindingEngine: BindingEngine) {
    super(router, logger);
    super.initialize(new ServiceBase<Zeus.Web.Model.Location>(Constants.EntityTypeNames.Location));

    this.locationVolumeService = new ServiceBase<Zeus.Web.Model.LocationVolume>(Constants.EntityTypeNames.LocationVolume);
    this.storageVolumeService = new ServiceBase<Zeus.Web.Model.StorageVolume>(Constants.EntityTypeNames.StorageVolume);
    this.storageVolumeService.gridDataSource.expands = ['volumeType'];
    this.locationService = new ServiceBase<Zeus.Web.Model.Location>(Constants.EntityTypeNames.Location);
    this.storageService = new ServiceBase<Zeus.Web.Model.Storage>(Constants.EntityTypeNames.Storage);
    this.articleRotationLevelTypeService = new EnumerationTypeService(Constants.EnumerationTypes.ArticleRotationLevel);
    this.locationVolumes = [];
    this.trayTemplateService = new ServiceBase<Zeus.Web.Model.TrayTemplate>(Constants.EntityTypeNames.TrayTemplate);
    this.applicationParameterService = new ServiceBase<Zeus.Web.Model.ApplicationParameters>(Constants.EntityTypeNames.ApplicationParameters);
  }

  public async addLocationVolume() {
    let locationVolume = await this.locationVolumeService.createEntity();
    locationVolume.capacityInVolumeType = 1
    this.locationVolumes.push(locationVolume);
  }

  @computedFrom("trayTemplate", "storage.storageTypeId", "locationVolumes.length")
  private get displayAddButton() {
    if (this.trayTemplate != null)
      return true;

    if (this.storage?.storageTypeId === MSConstants.StorageTypes.Static)
      return true;

    if (this.storage?.storageTypeId === Constants.StorageTypes.Dynamic && this.locationVolumes.length < 1)
      return true;

    return false;
  }

  @computedFrom("storage", "storage.name")
  public get ribbonHeaderText() {
    if (this.storage != null) {
      return this.i18n.tr("location.locationFor") + this.storage.name;
    }
    return this.i18n.tr("location.location");
  }

  @computedFrom("storage")
  public get documentTitle(): any {
    return "";
  }

  public removeLocationVolume(locationVolume: Zeus.Web.Model.LocationVolume) {
    this.locationVolumes.splice(this.locationVolumes.findIndex(x => x.id == locationVolume.id));
  }

  public async activate(params) {
    this.foreignEntityId = params.param1;
    if (params.callback != null)
      params.callback(this);

    if (this.storage != null) {
      this.storageTypeId = this.storage.storageTypeId;
    }
    if (this.isFromTemplate) {
      this.trayTemplateService.gridDataSource.customSelect2Predicates = () => new Predicate("storageId", FilterQueryOp.Equals, this.storage.id);
    }
  }

  public async saveCurrentEntity() {
    return null;
  }

  public async preview() {
    this.previewBusy = true;
    try {
      const cleanMask = Utils.replaceAll(this.mask, "\\+", "%2B");
      let response;
      if (this.storage != null) {
        if (!this.isFromTemplate) {
          response = await this.httpClient.get("/api/location/preview?storageId=" + this.storage.id + "&mask=" + cleanMask);
        } else if (this.trayTemplate != null) {
          response = await this.httpClient.get("/api/location/preview?storageId=" + this.storage.id + "&mask=" + cleanMask + "&trayTemplateId=" + this.trayTemplate);
        }
      } else if (this.foreignEntityId != null) {
        response = await this.httpClient.get("/api/location/preview?trayTemplateId=" + this.foreignEntityId + "&mask=" + cleanMask);
      }

      if (response.ok) {
        this.previewResults = await response.json();
      }
      this.previewBusy = false;
    }
    catch (error) {
      this.previewBusy = false;
      throw error;
    }
  }
  public async createLocations() {
    this.creationBusy = true;
    try {
      let params = {} as Zeus.Web.Model.AutoLocationsCreationParamsDTO;
      if (this.storage != null) {
        params.storageId = this.foreignEntityId;
        if (this.trayTemplate != null) {
          params.trayTemplateId = this.trayTemplate as any;
        }
      } else {
        if (this.foreignEntityId != null) {
          params.trayTemplateId = this.foreignEntityId;
        }
      }
      params.mask = this.mask;
      params.storageUnitHeight = this.storageUnitHeight;
      params.storageUnitLength = this.storageUnitLength;
      params.storageUnitWidth = this.storageUnitWidth;
      params.dedicatedToRotationLevelId = this.dedicatedToRotationLevelId;
      params.moreThanOneArticleAllowed = this.moreThanOneArticleAllowed;
      params.notAvailableForInput = this.notAvailableForInput;
      params.notAvailableForPicking = this.notAvailableForPicking;
      params.locationVolumes = [];
      if (this.locationVolumes != null && this.locationVolumes.length > 0) {
        this.locationVolumes.forEach(locVolume => {
          params.locationVolumes.push({ "capacityInVolumeType": locVolume.capacityInVolumeType, "storageVolumeId": locVolume.storageVolumeId });
        });
      }

      let response = await this.httpClient.post("/api/location/create", JSON.stringify(params));
      if (response.ok) {
        this.dialogController.close(true);
      }
      this.creationBusy = false;
    }
    catch (error) {
      this.creationBusy = false;
      throw error;

    }
  }
  public async removeLocations() {
    this.deletionBusy = true;
    try {
      let params = {} as Zeus.Web.Model.AutoLocationsCreationParamsDTO;
      if (this.storage != null) {
        params.storageId = this.foreignEntityId;
      }
      if (this.foreignEntityId != null) {
        params.trayTemplateId = this.foreignEntityId;
      }
      params.mask = this.mask;
      let response = await this.httpClient.delete("/api/location", JSON.stringify(params));
      if (response.ok) {
        this.dialogController.close(true);
      }
      this.deletionBusy = false;
    }
    catch (error) {
      this.deletionBusy = false;
      throw error;

    }
  }

  public async createNewTemplate() {
    await this.box.showEditDialog(TrayTemplateDetail, -100, this.i18n.tr('traytemplate.addTrayTemplate'), (vm: TrayTemplateDetail) => {
      vm.selectedStorage = {
        id: this.storage.id,
        text: this.storage.name
      } as DataFormat;
      vm.isEditDialog = true;
    }).whenClosed(async (result) => {
      if (!result.wasCancelled) {
        let newEntity: Zeus.Web.Model.TrayTemplate = result.output;
        this.trayTemplate = {
          id: newEntity.id,
          text: newEntity.name
        };
      }
    });
  }
}
