import { ListViewModelBase, CustomLogger, ServiceBase, FieldType, GridHelper, Actions, SelectAllHeaderComponent, UIInternal } from 'digiwall-lib';
import { Router } from 'aurelia-router';
import { autoinject, computedFrom } from 'aurelia-framework';
import { Zeus } from "../generated";
import * as Constants from '../constants';
import { DialogService } from 'aurelia-dialog';
import { WorkOrderUtils } from 'utils/work-order-utils';
import { HttpClient, RequestInit } from 'aurelia-fetch-client';
import { RowNode } from 'ag-grid-community';
import { Reception } from 'reception/generated';

@autoinject
export class InputWorkorderMissingDataList extends ListViewModelBase<Zeus.Web.Model.MissingDataInputWorkOrderLine> {
  public ressourceName: string = Constants.EntityTypeNames.WorkOrder;

  private projectService: ServiceBase<Zeus.Web.Model.Project>;
  private costCenterService: ServiceBase<Zeus.Web.Model.CostCenter>;

  constructor(router: Router, logger: CustomLogger, private dialogService: DialogService, private httpClient: HttpClient) {
    super(router, logger, new ServiceBase<Zeus.Web.Model.MissingDataInputWorkOrderLine>(Constants.EntityTypeNames.MissingDataInputWorkOrderLine));
    this.service.gridDataSource.expands = ['woLine.article.unitOfMeasures.unitOfMeasure', "woLine.article.articleStorageGroups.storageLocationType1", "woLine.article.articleStorageGroups.storageStrategy",
      "woLine.article.articleStorageGroups.articleRotationLevel", "woLine.article.articleVolumeConfigs.storageVolume.volumeType"];
    this.service.gridDataSource.queryParameters = { missingDataOnly: true };
    UIInternal.subscribe(UIInternal.EVT_PAGINATION_CHANGE, async () => {
      this.gridOptions?.api?.forEachNode(async (x: RowNode<Zeus.Web.Model.MissingDataInputWorkOrderLine>) => {
        if (x.data?.woLine?.article?.pictureUrl == null) {
          return;
        }
        try {
          const response = await fetch(x.data.woLine.article.pictureUrl, {
            method: 'GET',
            mode: 'cors',
          });

          (x.data as any).imageOk = response.ok;
        } catch (error) {
          (x.data as any).imageOk = false;
        }
      });
    });
  }

  public getDetailsUrl(self, entity: Zeus.Web.Model.MissingDataInputWorkOrderLine) {
    if (entity && entity.id) {
      return '/input-order-missing-data/' + entity.id;
    }
    return null;
  }

  @computedFrom('authService.currentUser.id')
  public get canUpdate() {
    return WorkOrderUtils.hasActionAccessOnAnyType(this.authService, Actions.Update);
  }

  public override async activate(params: any): Promise<void> {
    await super.activate(params);
  }

  private getCellStyle(warningFlag: boolean): any {
    return warningFlag ? { "background-color": Constants.GridColors.Warning } : null;
  }

  public getDataGridColumns() {
    this.projectService = new ServiceBase<Zeus.Web.Model.Project>(Constants.EntityTypeNames.Project);
    this.costCenterService = new ServiceBase<Zeus.Web.Model.CostCenter>(Constants.EntityTypeNames.CostCenter);

    return [
      {
        headerName: this.i18n.tr("workorder.workOrderName"),
        field: "woName",
        type: FieldType.String,
        sort: 'asc',
      },
      {
        headerName: this.i18n.tr("article.reference"),
        field: "woLine.article.reference",
        type: FieldType.String,
        sort: 'asc'
      },
      {
        headerName: this.i18n.tr("article.description"),
        field: "woLine.article.description._translation",
        type: FieldType.String,
      },
      {
        headerName: this.i18n.tr("articleunitofmeasuresize.baseUOM"),
        valueGetter: (params) => {
          const entity = params.data;
          if (entity?.woLine?.article && Array.isArray(entity.woLine.article.unitOfMeasures)) {
            const unitOfMeasure = entity.woLine.article.unitOfMeasures.find(x => x.defaultInputUOM);
            return unitOfMeasure ? unitOfMeasure.unitOfMeasure.denomination._translation : null;
          };
          return null;
        },
        type: FieldType.Enumeration,
        sortable: false,
      },
      {
        headerName: this.i18n.tr("articleunitofmeasuresize.width"),
        valueGetter: (params) => {
          const entity = params.data;
          if (entity?.woLine?.article && Array.isArray(entity.woLine.article.unitOfMeasures)) {
            const unitOfMeasure = entity.woLine.article.unitOfMeasures.find(x => x.defaultInputUOM);
            return unitOfMeasure?.width > 0 ? unitOfMeasure?.width : null;
          }
          return null;
        },
        type: FieldType.Number,
        hide: true,
        sortable: false,
        cellStyle: (params) => {
          return params.data && params.data.uomWidthWarning != null ? this.getCellStyle(params.data.uomWidthWarning) : null;
        },
      },
      {
        headerName: this.i18n.tr("articleunitofmeasuresize.height"),
        valueGetter: (params) => {
          const entity = params.data;
          if (entity?.woLine?.article && Array.isArray(entity.woLine.article.unitOfMeasures)) {
            const unitOfMeasure = entity.woLine.article.unitOfMeasures.find(x => x.defaultInputUOM);
            return unitOfMeasure?.height > 0 ? unitOfMeasure?.height : null;
          }
          return null;
        },
        type: FieldType.Number,
        hide: true,
        sortable: false,
        cellStyle: (params) => {
          return params.data && params.data.uomHeightWarning != null ? this.getCellStyle(params.data.uomHeightWarning) : null;
        },
      },
      {
        headerName: this.i18n.tr("articleunitofmeasuresize.depth"),
        valueGetter: (params) => {
          const entity = params.data;
          if (entity?.woLine?.article && Array.isArray(entity.woLine.article.unitOfMeasures)) {
            const unitOfMeasure = entity.woLine.article.unitOfMeasures.find(x => x.defaultInputUOM);
            return unitOfMeasure?.depth > 0 ? unitOfMeasure?.depth : null;
          }
          return null;
        },
        type: FieldType.Number,
        hide: true,
        sortable: false,
        cellStyle: (params) => {
          return params.data && params.data.uomDepthWarning != null ? this.getCellStyle(params.data.uomDepthWarning) : null;
        },
      },
      {
        headerName: this.i18n.tr("articleunitofmeasuresize.weight"),
        valueGetter: (params) => {
          const entity = params.data;
          if (entity?.woLine?.article && Array.isArray(entity.woLine.article.unitOfMeasures)) {
            const unitOfMeasure = entity.woLine.article.unitOfMeasures.find(x => x.defaultInputUOM);
            return unitOfMeasure?.weight > 0 ? unitOfMeasure?.weight : null;
          }
          return null;
        },
        type: FieldType.Number,
        hide: true,
        sortable: false,
        cellStyle: (params) => {
          return params.data && params.data.uomWeightWarning != null ? this.getCellStyle(params.data.uomWeightWarning) : null;
        },
      },
      {
        headerName: this.i18n.tr("missingdatainputworkorderline.defaultVolume"),
        valueGetter: (params) => {
          const entity = params.data;
          if (entity?.woLine?.article && Array.isArray(entity.woLine.article.articleVolumeConfigs)) {
            const articleVolumeConfig = entity.woLine.article.articleVolumeConfigs.find(x => x.default);
            if (articleVolumeConfig != null) {
              return articleVolumeConfig.storageVolume.volumeType.denomination._translation ?
                articleVolumeConfig.storageVolume.volumeType.denomination._translation : null;
            };
          };
          return null;
        },
        type: FieldType.Enumeration,
        sortable: false,
        cellStyle: (params) => {
          return params.data && params.data.defaultVolumeWarning != null ? this.getCellStyle(params.data.defaultVolumeWarning) : null;
        },
      },
      {
        headerName: this.i18n.tr("article.storageLocationType1Id"),
        valueGetter: (params) => {
          const entity = params.data;
          if (entity?.woLine?.article && Array.isArray(entity.woLine.article.articleStorageGroups)) {
            const articleStorageGroup = entity.woLine.article.articleStorageGroups.find(x => x.default);
            if (articleStorageGroup != null && articleStorageGroup.storageLocationType1 != null) {
              return articleStorageGroup.storageLocationType1.denomination._translation ?
                articleStorageGroup.storageLocationType1.denomination._translation : null;
            };
          };
          return null;
        },
        type: FieldType.Enumeration,
        cellStyle: (params) => {
          return params.data && params.data.locationTypeWarning != null ? this.getCellStyle(params.data.locationTypeWarning) : null;
        },
      },
      {
        headerName: this.i18n.tr("article.articleRotationLevel"),
        valueGetter: (params) => {
          const entity = params.data;
          if (entity?.woLine?.article && Array.isArray(entity.woLine.article.articleStorageGroups)) {
            const articleStorageGroup = entity.woLine.article.articleStorageGroups.find(x => x.default);
            if (articleStorageGroup != null && articleStorageGroup.articleRotationLevel != null) {
              return articleStorageGroup.articleRotationLevel.denomination._translation ?
                articleStorageGroup.articleRotationLevel.denomination._translation : null;
            };
          };
          return null;
        },
        type: FieldType.Enumeration,
        hide: true,
        cellStyle: (params) => {
          return params.data && params.data.articleRotationWarning != null ? this.getCellStyle(params.data.articleRotationWarning) : null;
        },
      },
      {
        headerName: this.i18n.tr("articlestoragegroup.storageStrategyId"),
        valueGetter: (params) => {
          const entity = params.data;
          if (entity?.woLine?.article && Array.isArray(entity.woLine.article.articleStorageGroups)) {
            const articleStorageGroup = entity.woLine.article.articleStorageGroups.find(x => x.default);
            if (articleStorageGroup != null && articleStorageGroup.storageStrategy != null) {
              return articleStorageGroup.storageStrategy.denomination._translation ?
                articleStorageGroup.storageStrategy.denomination._translation : null;
            };
          };
          return null;
        }, type: FieldType.Enumeration,
        cellStyle: (params) => {
          return params.data && params.data.handlingWarning != null ? this.getCellStyle(params.data.handlingWarning) : null;
        },
      },
      {
        headerName: this.i18n.tr("article.barcodeEan"),
        field: "woLine.article.barcodeEan",
        type: FieldType.String,
        cellStyle: (params) => {
          return params.data && params.data.eANWarning != null ? this.getCellStyle(params.data.eANWarning) : null;
        },
      },
      {
        headerName: this.i18n.tr("receptionparameters.picturePresent"),
        field: "",
        minWidth: Constants.AGGridColumnsWidth.IsSelected,
        cellRendererFramework: {
          template: '<template><ui-checkbox disabled.one-way=\"true\" checked.two-way=\"params.data.imageOk\"></ui-checkbox></template>'
        },
        suppressToolPanel: true,
        suppressMenu: true,
        sortable: false,
        resizable: true,
        filter: false,
        pinned: false,
        hide: true,
        headerComponentParams: { VM: this },
        cellStyle: (params) => {
          return params.data && params.data.picturePresentWarning != null ? this.getCellStyle(params.data.picturePresentWarning) : null;
        },
      },
      {
        headerName: this.i18n.tr("receptionparameters.pictureRecent"),
        field: "",
        minWidth: Constants.AGGridColumnsWidth.IsSelected,
        cellRendererFramework: {
          template: '<template><ui-checkbox disabled.one-way=\"true\" checked.two-way=\"!params.data.pictureRecentWarning\"></ui-checkbox></template>'
        },
        suppressToolPanel: true,
        suppressMenu: true,
        sortable: false,
        resizable: true,
        filter: false,
        pinned: false,
        hide: true,
        headerComponentParams: { VM: this },
        cellStyle: (params) => {
          return params.data && params.data.pictureRecentWarning != null ? this.getCellStyle(params.data.pictureRecentWarning) : null;
        },
      },
      {
        headerName: this.i18n.tr("receptionparameters.nbrLocationsBelowMax"),
        field: "",
        minWidth: Constants.AGGridColumnsWidth.IsSelected,
        cellRendererFramework: {
          template: '<template><ui-checkbox disabled.one-way=\"true\" checked.two-way=\"!params.data.nbrLocationBelowMaxWarning\"></ui-checkbox></template>'
        },
        suppressToolPanel: true,
        suppressMenu: true,
        sortable: false,
        resizable: true,
        filter: false,
        pinned: false,
        hide: true,
        headerComponentParams: { VM: this },
        cellStyle: (params) => {
          return params.data && params.data.nbrLocationBelowMaxWarning != null ? this.getCellStyle(params.data.nbrLocationBelowMaxWarning) : null;
        },
      },
    ];
  }
}
