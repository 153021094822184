import { Container } from 'aurelia-framework';
import { I18N } from 'aurelia-i18n';
import { FilterQueryOp, Predicate } from 'breeze-client';
import { AuthService, Box, CustomLogger, DialogBoxViewModel, ServiceBase } from 'digiwall-lib';
import { Zeus } from 'generated';
import { ApiService } from '../external-src/api.service';
import * as Constants from '../constants';
import { HttpClient } from 'aurelia-fetch-client';
import { Endpoints } from 'endpoints';
import { LocationForActionService } from '../external-src/utils/location-for-action-service';
import { FreePicking } from '../app-modules/free-picking/generated';

export class WorkOrderUtils {

  public static get resourcesPerType(): string[] {
    return ['input', 'picking', 'inventory'].map(x => 'workordertype' + x);
  }

  public static hasActionAccessOnAnyType(authService: AuthService, action: string): boolean {
    return this.resourcesPerType.some(resource => authService.checkPermissonAccess({ resource, action }));
  }

  public static getResourceForType(entity: Zeus.Web.Model.WorkOrder | number) {
    if (typeof entity != 'number') {
      entity = entity.workOrderTypeId;
    }
    // entity is of type numbers
    switch (entity) {
      case Constants.WorkOrderType.Input: return this.resourcesPerType[0];
      case Constants.WorkOrderType.Picking: return this.resourcesPerType[1];
      case Constants.WorkOrderType.Inventory: return this.resourcesPerType[2];
    }
    return null;
  }

  /**
   * Indicate if the requested quantity of the given ArticleToAction is filled by allocated and pre-allocated locations for input.
   */
  public static requestedQuantityIsFilled(ata: Zeus.Web.Model.ArticleToAction): boolean {
    return ata.requestedActionQuantity != ata.locationForInputPickings.reduce((sum, lfip) => sum + (lfip.msgHasBeenSent ? lfip.reallyActionQuantity : lfip.requestedActionQuantity), 0);
  }

  // Avoid fetching locations twice in parallel
  public static async getLocationForInputPickingFromList(articleToActions: Zeus.Web.Model.ArticleToAction[], apiService: ApiService, workOrderService: ServiceBase<Zeus.Web.Model.WorkOrder>, getLocationForInputPickingFromListInProgress: Map<number, boolean>): Promise<Array<Zeus.Web.Model.GetLocationForActionResponse>> {
    articleToActions = (articleToActions || []).filter(ata => {
      if (getLocationForInputPickingFromListInProgress.get(ata.id)) {
        // Already in progress
        return false;
      }
      // Set to in progress
      getLocationForInputPickingFromListInProgress.set(ata.id, true);
      return true;
    });

    let result: Array<Zeus.Web.Model.GetLocationForActionResponse> = null;
    if (articleToActions.length > 0) {
      result = await apiService.getLocationForInputPickingFromList(articleToActions);

      // Remove progress markers
      articleToActions.forEach(ata => getLocationForInputPickingFromListInProgress.delete(ata.id));

      // Only refresh WOs with ATAs that have been treated
      let workOrderIdsPredicate = Object.keys(articleToActions.reduce((map: object, ata) => Object.assign(map, { [ata.actionWorkOrderId]: true }), {}))
        .map(id => new Predicate('id', FilterQueryOp.Equals, parseInt(id)));
      await workOrderService.getEntities(Predicate.or(workOrderIdsPredicate), ["articleToActions.locationForInputPickings.location.trayContainer.storage"]);
    }
    return Promise.resolve(result);
  }

  public static async verifyLocationsForInventory(inventoryLocation: Zeus.Web.Model.InventoryWorkOrderArticle[]) {
    let result: Array<Zeus.Web.Model.GetInventoryWOArticleLocationDTO> = null;
    if (inventoryLocation.length > 0) {
      const http = Container.instance.get(HttpClient);
      let response = await http.post(Endpoints.BatchProcess.VerifyInventoryWOArticleLocations, JSON.stringify(inventoryLocation.map(a => a.id)));
      if (response.ok) {
        result = await response.json();
      }
    }
    return Promise.resolve(result);
  }

  public static async cancelArticleToAction(articleToActionService: ServiceBase<Zeus.Web.Model.ArticleToAction>, articleToActions: Array<Zeus.Web.Model.ArticleToAction>): Promise<boolean> {
    articleToActions = articleToActions.filter(ata => !ata.hasBeenCancelled && ata.requestedActionQuantity != ata.reallyActionQuantity);
    if ((await this.deleteOrCancelArticleToAction(articleToActions)).continue) {
      articleToActions.forEach(ata => ata.hasBeenCancelled = true);
      return true;
    }
    return false;
  }

  public static async deleteOrCancelArticleToAction(articleToActions: Array<Zeus.Web.Model.ArticleToAction>): Promise<{ continue: boolean, modalShown?: boolean }> {
    let i18n = Container.instance.get(I18N);
    let box = Container.instance.get(Box);
    let lfaService = Container.instance.get(LocationForActionService);

    if (false == await lfaService.canDeleteOrCancelArticleToAction(articleToActions)) {
      return { continue: false, modalShown: true };
    }

    if (articleToActions.some(ata => ata.locationForInputPickings.some(loc => loc.manuallyBlocked))) {
      return await box.showQuestion(
        i18n.tr("articletoinput.manuallyBlockedLines"), i18n.tr("menu.del-filter-set-title"),
        [
          { label: i18n.tr('general.no'), theme: 'dark', type: 'ghost', fn: (dialogBox?: DialogBoxViewModel) => dialogBox.controller.ok(false) },
          { label: i18n.tr('general.yes'), theme: 'primary', type: 'solid', fn: (dialogBox?: DialogBoxViewModel) => dialogBox.controller.ok(true) }
        ]
      ).whenClosed(result => {
        return {
          continue: result.output,
          modalShown: true
        };
      });
    }
    return { continue: true };
  }

  public static async getLaunchBatchProcessDTO(elements: Zeus.Web.Model.ArticleToAction[] | Zeus.Web.Model.InventoryWorkOrderManyToMany[] | FreePicking.Model.TrayPickWorkOrder[], workOrders: Zeus.Web.Model.WorkOrder[]): Promise<Zeus.Web.Model.LaunchBatchProcessDTO> {
    if (elements.length == 0 && workOrders.length == 0) {
      throw new Error('workordertoprocess.noBatchProcessToSend');
    }

    //WO = input or Picking
    if (elements.length > 0 && (elements[0] as Zeus.Web.Model.ArticleToAction).locationForInputPickings) {
      let articleToActions = elements as Zeus.Web.Model.ArticleToAction[];
      // Ensure storages and traycontainers are loaded
      let locationIds: number[] = [];
      let trayContainerIds: number[] = [];
      let storageIds: number[] = [];
      articleToActions.forEach(ata => {
        locationIds.push(...ata.locationForInputPickings
          .filter(lfip => lfip.location == null)
          .map(lfip => lfip.locationId)
        )
        trayContainerIds.push(...ata.locationForInputPickings
          .filter(lfip => lfip.location != null && lfip.location.trayContainer == null)
          .map(lfip => lfip.location.trayContainerId)
        );
        storageIds.push(...ata.locationForInputPickings
          .filter(lfip => lfip.location != null && lfip.location.trayContainer != null && lfip.location.storage == null)
          .map(lfip => lfip.location.storageId)
        );
      });

      let getPredicate = (ids: number[]): Predicate =>
        Predicate.or(ids.map(id => new Predicate('id', FilterQueryOp.Equals, id)));

      let promises: Promise<any>[] = [];
      if (locationIds.length > 0) {
        promises.push(new ServiceBase<Zeus.Web.Model.Location>(Constants.EntityTypeNames.Location)
          .getEntities(getPredicate(locationIds), ['trayContainer.storage'])
        );
      }
      if (trayContainerIds.length > 0) {
        promises.push(new ServiceBase<Zeus.Web.Model.TrayContainer>(Constants.EntityTypeNames.TrayContainer)
          .getEntities(getPredicate(trayContainerIds), ['storage'])
        );
      }
      if (storageIds.length > 0) {
        promises.push(new ServiceBase<Zeus.Web.Model.Storage>(Constants.EntityTypeNames.Storage)
          .getEntities(getPredicate(storageIds))
        );
      }

      await Promise.all(promises);

      return {
        workOrderTypeId: articleToActions[0].actionWorkOrder.workOrderTypeId,
        articleToActionIds: articleToActions.map(x => x.id)
      };
    }

    //WO = TrayPick
    const firstElem = (elements[0] as FreePicking.Model.TrayPickWorkOrder);
    if (elements.length > 0 && firstElem.trayContainerId && firstElem.workOrder.workOrderTypeId == Constants.WorkOrderType.Picking) {
      return {
        workOrderTypeId: firstElem.workOrder.workOrderTypeId,
        trayPickWorkOrderIds: elements.map(x => x.id)
      };
    }

    //WO = Inventory
    let dto: Zeus.Web.Model.LaunchBatchProcessDTO = {
      workOrderTypeId: Constants.WorkOrderType.Inventory,
      inventoryArticleIds: [], inventoryStorageIds: [], inventoryTrayContainerIds: [], inventoryArticleLocationIds: [], workOrderIds: [],
    };

    // Check if there are lines without any location
    if (elements.some((inv: any) => inv.isSelected && inv.nbrLocations == 0)) {
      let i18n = Container.instance.get(I18N);
      let box = Container.instance.get(Box);

      let abort = await box.showQuestion(i18n.tr('workordertoprocess.emptyInventoryLines.message'),
        i18n.tr('workordertoprocess.emptyInventoryLines.title'),
        [
          { label: i18n.tr('general.no'), theme: 'dark', type: 'ghost', fn: (dialogBox?: DialogBoxViewModel) => dialogBox.controller.cancel() },
          { label: i18n.tr('general.yes'), theme: 'primary', type: 'solid', fn: (dialogBox?: DialogBoxViewModel) => dialogBox.controller.ok() }
        ]
      ).whenClosed(result => result.wasCancelled);

      if (abort) {
        return;
      }
    }

    elements.forEach(inv => {
      if ((inv as Zeus.Web.Model.InventoryWorkOrderArticle).articleId != null) {
        if ((inv as Zeus.Web.Model.InventoryWorkOrderArticle).workOrder.inventoryTargetId == Constants.InventoryTarget.Article) {
          dto.inventoryArticleIds.push(inv.id);
        } else {
          dto.inventoryArticleLocationIds.push(inv.id);
        }
      } else if ((inv as Zeus.Web.Model.InventoryWorkOrderStorage).storageId != null) {
        dto.inventoryStorageIds.push(inv.id);
      } else if ((inv as Zeus.Web.Model.InventoryWorkOrderTrayContainer).trayContainerId != null) {
        dto.inventoryTrayContainerIds.push(inv.id);
      }
    });
    if (elements.length == 0) {
      dto.workOrderTypeId = workOrders[0].workOrderTypeId;
    }
    dto.workOrderIds.push(...workOrders.map(x => x.id));
    return dto;
  }

  public static async launchWorkOrder(
    workOrder: Zeus.Web.Model.WorkOrder,
    actionBeforeMessage: () => Promise<void> = null,
    actionAfterMessage: () => Promise<void> = null
  ) {
    var apiService = Container.instance.get(ApiService);
    await this.launchBatch(workOrder, (workOrder) => apiService.LaunchWorkOrder(workOrder), actionBeforeMessage, actionAfterMessage);
  }

  public static async launchArticleToActions(
    lineIds: number[],
    actionBeforeMessage: () => Promise<void> = null,
    actionAfterMessage: () => Promise<void> = null
  ) {
    var apiService = Container.instance.get(ApiService);
    await this.launchBatch(lineIds, (lineIds) => apiService.LaunchArticleToActions(lineIds), actionBeforeMessage, actionAfterMessage);
  }

  public static async launchInventoryLines(
    dto: string,
    actionBeforeMessage: () => Promise<void> = null,
    actionAfterMessage: () => Promise<void> = null
  ) {
    var apiService = Container.instance.get(ApiService);
    await this.launchBatch(dto, (dto) => apiService.LaunchInventoryLines(dto), actionBeforeMessage, actionAfterMessage);
  }

  private static async launchBatch<T>(
    elementsToSend: T,
    launchBatchFn: (elements: T) => Promise<string>,
    actionBeforeMessage: () => Promise<void> = null,
    actionAfterMessage: () => Promise<void> = null
  ) {
    var i18n = Container.instance.get(I18N);
    var logger = Container.instance.get(CustomLogger);
    try {
      if (elementsToSend != null) {
        let response = await launchBatchFn(elementsToSend);
        if (actionBeforeMessage != null) {
          await actionBeforeMessage();
        }
        logger.Log(i18n.tr('workordertoprocess.' + response), null, null, true);
        if (response != 'noBatchProcessToSend' && response != 'batchLocked' && actionAfterMessage != null) {
          await actionAfterMessage();
        }
      }
    } catch (e) {
      logger.LogError(i18n.tr((e as Error).message), null, null, true);
    }
  }
}


