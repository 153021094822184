export class Application {
  public static IsBinAlreadyInUse = "/api/Conveyor/Utils/IsBinAlreadyInUse";
  public static SendMessage = "/api/Conveyor/Utils/SendMessage";
  public static CancelBin = "/api/Conveyor/Utils/CancelBin";
  public static ResendToPlc = "/api/Conveyor/Utils/ResendToPlc";
}

export class Permissions {
  public static LaunchPickBin = 'launch-pick-bin';
  public static BinOverview = 'binOverview';
}

export class EntityTypeNames {
  public static ConveyorConfiguration = "ConveyorConfiguration";
  public static AutoLaunchStation = "AutoLaunchStation";
  public static ExitStation = "ExitStation";
  public static ConveyorBin = "ConveyorBin";
  public static StationDescription = "StationDescription";

  public static ResourceNames = {
    ConveyorConfiguration: "ConveyorConfigurations",
    AutoLaunchStation: "AutoLaunchStations",
    ExitStation: "ExitStations",
    ConveyorBin: "ConveyorBins",
    StationDescription: "StationDescriptions"
  };
}
