import { Router } from 'aurelia-router';
import { CustomLogger, ServiceBase, ListViewModelBase, FieldType, getButtonsColDef, ButtonsColDefAction, Various, GridHelper } from 'digiwall-lib';
import { autoinject } from 'aurelia-framework';
import { Zeus } from 'generated';
import * as Constants from '../constants';
import { CellRenderer } from 'utils/cell-renderer';
import { ColDef } from 'ag-grid-community';
import { HttpClient } from 'aurelia-fetch-client';
import { ListViewModelActionHistoryFilter } from 'action-histories/action-history-list';
import { Endpoints } from 'endpoints';

@autoinject
export class ProcessMonitoring extends ListViewModelBase<Zeus.Web.Model.BatchProcess> {
  public ressourceName: string = Constants.EntityTypeNames.BatchProcess;

  constructor(router: Router, logger: CustomLogger, private cellRenderer: CellRenderer, private httpClient: HttpClient) {
    super(router, logger, new ServiceBase<Zeus.Web.Model.BatchProcess>(Constants.EntityTypeNames.BatchProcess));
    this.service.gridDataSource.expands = ['workOrderBatchProcesses', 'executionStatus', 'resultStatus', 'storageType'];
  }

  public getDetailsUrl(self, entity: Zeus.Web.Model.BatchProcess) {
    return '/order-tracking/all/' + entity.id;
  }

  public openActionHistory(): void {
    if (this.selectedEntities.length > 0) {
      let ids = this.selectedEntities.map(e => e.id);
      let filter = new ListViewModelActionHistoryFilter('processMonitoring', ids);
      this.navigateTo('action-history/all/' + filter.encode());
    }
  }

  // Temporarily hidden (#8626)
  // public async closeWorkOrder() {
  //   let workOrdersId = [];
  //   this.selectedEntities.forEach(x => {
  //     x.workOrderBatchProcesses.forEach(y => {
  //       if (!workOrdersId.find(z => z == y.workOrderId)) {
  //         workOrdersId.push(y.workOrderId)
  //       }
  //     })
  //   })
  //   let response = await this.httpClient.post(Endpoints.WorkOrder.CloseWorkOrder, JSON.stringify(workOrdersId));
  //   if (response.ok) {
  //     this.log(this.i18n.tr('ordertracking.finishWorkOrder'), null, true);
  //   } else {
  //     this.logError(await response.text(), null, true);
  //   }
  // }

  public afterResetFilters() {
    this.agGridSortingHelper.applyDefaultSortModel();
  }

  public getDataGridColumns() {
    let defs: ColDef[] = [
      GridHelper.getSelectedColDef(this),
      getButtonsColDef<Zeus.Web.Model.BatchProcess>([
        new ButtonsColDefAction<Zeus.Web.Model.BatchProcess>({
          icon: 'digi-impossible',
          uiTheme: 'danger',
          title: this.i18n.tr('ordertracking.cancelBatch'),
          hidden: batchProcess => batchProcess?.executionStatusId != Constants.BatchProccesExecutionStatus.InProgress,
          clicked: async batchProcess => {
            let response = await this.httpClient.post(Endpoints.BatchProcess.cancelBatchProcess, JSON.stringify([batchProcess.id]));
            if (response.ok) {
              this.log(this.i18n.tr('ordertracking.batchCancelled'), null, true);
              // Refresh data
              await this.service.getEntityById(batchProcess.id, ...['workOrderBatchProcesses', 'executionStatus', 'resultStatus', 'storageType']);
            } else {
              this.logError(await response.text(), null, true);
            }
          }
        })
      ]),
      {
        headerName: this.i18n.tr("batchprocess.id"),
        field: "id",
        type: FieldType.Number,
        sort: 'desc'
      },
      {
        headerName: this.i18n.tr("batchprocess.storageTypeId"),
        field: "storageType.denomination._translation",
        type: FieldType.Enumeration,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: Constants.EnumerationTypes.BatchProcessStorageType,
        }
      },
      {
        headerName: this.i18n.tr("batchprocess.startProcessDateTime"),
        field: "startProcessDateTime",
        type: FieldType.Date,
        valueFormatter: (data) => { return this.cellRenderer.dateRenderer(data, Various.DateFormatWithHours); },
      },
      {
        headerName: this.i18n.tr("batchprocess.endProcessDateTime"),
        field: "endProcessDateTime",
        type: FieldType.Date,
        valueFormatter: (data) => { return this.cellRenderer.dateRenderer(data, Various.DateFormatWithHours); },
      },
      {
        headerName: this.i18n.tr("batchprocess.createdByFullName"),
        field: "createdByFullName",
        type: FieldType.User,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          userServiceName: Constants.EntityTypeNames.ZeusUser,
        }
      },
      {
        headerName: this.i18n.tr("batchprocess.workOrderBatchProcesses"),
        field: "nbrWorkOrders",
        type: FieldType.Number,
      },
      {
        headerName: this.i18n.tr("batchprocess.dynamicStorageSentMessages"),
        field: "nbrSentMessages",
        type: FieldType.Number,
      },
      {
        headerName: this.i18n.tr("batchprocess.nbrReturnMessagesInError"),
        field: "nbrReturnMessagesInError",
        type: FieldType.Number,
      },
      {
        headerName: this.i18n.tr("batchprocess.executionStatusId"),
        field: "executionStatus.denomination._translation",
        type: FieldType.Enumeration,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: Constants.EnumerationTypes.BatchProcessExecutionStatus,
        }
      },
      {
        headerName: this.i18n.tr("batchprocess.resultStatus"),
        field: "resultStatus.denomination._translation",
        type: FieldType.Enumeration,
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          category: Constants.EnumerationTypes.BatchProcessResultStatus,
        }
      },
      ...GridHelper.getBaseEntityColDef(Constants.EntityTypeNames.ZeusUser),
    ];
    return defs;
  }
}
