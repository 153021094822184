export enum StorageTypes {
  Static = 31
};

export class EntityTypeNames {
  public static StaticRackLocation = "StaticRackLocation";

  public static ResourceNames = {
    StaticRackLocation: "StaticRackLocations"
  }
}

export enum EnumLocationLevelLevels {
  StaticStorageName = 1,
  Row = 2,
  Bay = 3,
  Level = 4,
  Position = 5,
  Compartment = 6,
}