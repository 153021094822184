import { Entity, FilterQueryOp, Predicate } from "breeze-client";
import { Zeus } from "generated";
import { DataFormat } from "select2";
import * as Constants from '../constants';

export class Utils {
  // The DataFormat is unstable, one time we access the id directly, another time we have to use .id
  public static extractIdFromDataFormat(df: DataFormat): number {
    if (df == null) return null;
    return Number.isInteger(df) ? Number.parseInt(df as any)
      : (typeof (df.id) == 'string' ? Number.parseInt(df.id) : df.id);
  }

  public static hasDecimals(val: number): boolean {
    return val - Math.round(val) !== 0;
  }

  public static isNullOrEmpty(str: string): boolean {
    return str == null || str.trim().length == 0;
  }

  public static storagePredicateLimitedToStorageGroup(storageGroup: Zeus.Web.Model.StorageGroup, prefix: string = ""): Predicate {
    let limitPredicate: Predicate = new Predicate('isUnavailable', FilterQueryOp.Equals, false);
    if (storageGroup != null) {
      // Storages are always linked to a ZONE
      let levels = Constants.StorageGroupTypes.Zone - storageGroup.storageGroupTypeId;
      if (levels == 0) {
        limitPredicate = limitPredicate.and(new Predicate(prefix + 'storageGroupId', FilterQueryOp.Equals, storageGroup.id));
      } else {
        let propLvls = '';
        for (; levels > 0; --levels) {
          propLvls += '.parentStorageGroup';
        }
        limitPredicate = limitPredicate.and(new Predicate(prefix + 'storageGroup' + propLvls + 'Id', FilterQueryOp.Equals, storageGroup.id));
      }
    }
    return limitPredicate;
  }

  public static detachOrDeleteEntity(entities: null | Entity | Entity[]) {
    if (entities == null) return;
    // When deleting list of entities that is a one-to-many, the array is modified when deleting. Hence copy the array
    let entitiesToDelete: Entity[] = isArray(entities) ? [...entities] : [entities];
    // Reverse loop: when deleting list of entities that is a one-to-many, the array is modified when deleting
    /* for(let i = entitiesToDelete.length - 1; i >= 0; --i) {

    } */
    entitiesToDelete.forEach(entity => {
      if (entity.entityAspect.entityState.isAdded()) {
        entity.entityAspect.setDetached();
      } else {
        entity.entityAspect.setDeleted();
      }
    });
  }

  public static arrayHasDuplicates(array: Array<any>): boolean {
    return array.some((item, index) => array.indexOf(item) !== index);
  }

  public static replaceAll(str, find, replace) {
    return str.replace(new RegExp(find, 'g'), replace);
  }

  public static hexToRgbA(hex, opacity = 1) {
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      let c = hex.substring(1).split('');
      if (c.length == 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = '0x' + c.join('');
      return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + opacity + ')';
    }
  }
}
