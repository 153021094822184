import { autoinject } from 'aurelia-framework';
import { HttpClient } from 'aurelia-fetch-client';
import * as Constants from '../../../constants';
import { Endpoints } from '../endpoints';
import { MobileScreens } from '../generated';

@autoinject()
export class StaticRackLocationApiService {
  constructor(private httpClient: HttpClient) { }

  public async valueIsInRange(body: MobileScreens.Model.IValueIsInRangeRequestDto): Promise<boolean> {
    try {
      let response = await this.httpClient.post(Constants.Application.RemoteServiceName + Endpoints.StaticRackLocation.ValueIsInRange, JSON.stringify(body))
      if (response.ok) {
        return await response.json();
      }
    } catch (error) {
      console.warn(error);
    }
    return false;
  }

  public async sequenceIsUnique(body: MobileScreens.Model.StaticRackLocationSequenceIsUniqueRequestDto): Promise<boolean> {
    try {
      let response = await this.httpClient.post(Constants.Application.RemoteServiceName + Endpoints.StaticRackLocation.SequenceIsUnique, JSON.stringify(body))
      if (response.ok) {
        return await response.json();
      }
    } catch (error) {
      console.warn(error);
    }
    return false;
  }
}



