import { camelCase } from "aurelia-framework";

export class MenuType {
  public static FreePicking = 'Free picking';
}

export class Application {
  public static UpdateTrayPickNumber = '/api/FreePicking/Utils/UpdateTrayPickNumber';
  public static Report = '/api/FreePicking/Report/FreePicking';
  public static SendTrayPicks = '/api/FreePicking/BatchProcess/SendTrayPicks';
}

export class EntityTypeNames {
  public static TrayPickWorkOrder = "TrayPickWorkOrder";
  public static ResourceNames = {
    TrayPickWorkOrder: "TrayPickWorkOrders",
  };
}
export class PermissionName {
  public static TrayPick = 'freePicking.trayPick';
  public static ExtraPick = 'freePicking.extraPick';
}

export class PermissionContexts {
  public static TrayPick = 'tray-pick';
  public static ExtraPick = 'extra-pick';
}

export class Permissions {
  public static TrayPick = camelCase(PermissionContexts.TrayPick);
  public static ExtraPick = camelCase(PermissionContexts.ExtraPick);
}

export class Action {
  public static Access = 'access';
}

export enum OrderSubtype {
  TrayPick = 124,
}

export class SequenceNumberKey {
  public static TrayPickingNumber = "TrayPickingNumber";
}

export enum ActionTraypickWOError {
  Launch, Cancel, Edit
}
