import { I18N } from 'aurelia-i18n';
import { bindable, customElement } from "aurelia-templating";
import { Box, AuthService, CustomLogger, DialogBoxViewModel, ServiceBase } from "digiwall-lib";
import { Zeus } from "generated";
import * as Constants from '../../constants';
import * as toastr from 'toastr';
import { autoinject, BindingEngine, computedFrom, Disposable } from 'aurelia-framework';
import { ApiService, MoveTrayToBayDto } from 'external-src/api.service';
import { Predicate, FilterQueryOp } from 'breeze-client';
import { DialogService } from 'aurelia-dialog';
import { MoveTrayToBay } from 'commands/move-tray-to-bay/move-tray-to-bay';

@autoinject
@customElement('article-detail-locations')
export class ArticleDetailLocations {

  @bindable
  private article: Zeus.Web.Model.Article;
  @bindable
  private disposables: Array<Disposable>;
  @bindable
  private canAddQty: boolean = true;
  @bindable
  private disabled: boolean = false;

  private articleLocationService: ServiceBase<Zeus.Web.Model.ArticleLocation>;
  private storageService: ServiceBase<Zeus.Web.Model.Storage>;
  private locationService: ServiceBase<Zeus.Web.Model.Location>;

  private articleLocations: Zeus.Web.Model.ArticleLocation[] = [];
  private newArticleLocation: Zeus.Web.Model.ArticleLocation;
  private otherArticleLocations: Zeus.Web.Model.ArticleLocation[];
  public uiTableLocations: HTMLElement;

  private locationsOnSameSite = {};

  constructor(private i18n: I18N, private logger: CustomLogger, private apiService: ApiService, private bindingEngine: BindingEngine,
    private dialogService: DialogService, private authService: AuthService, private box: Box) {
    this.articleLocationService = new ServiceBase<Zeus.Web.Model.ArticleLocation>(Constants.EntityTypeNames.ArticleLocation);
    this.storageService = new ServiceBase<Zeus.Web.Model.Storage>(Constants.EntityTypeNames.Storage);
    this.storageService.gridDataSource.expands = ['storageGroup.parentStorageGroup.parentStorageGroup']; // required for location filter
    this.storageService.gridDataSource.customSelect2Predicates = () => {
      if (this.article.articleStorageGroups?.length > 1) {
        // Limit to article storage groups (if length == 1, we have only default group, which has no storage group limit)
        let predicatesPerStorageGroup = this.article.articleStorageGroups.map(x => {
          return new Predicate('storageGroupId', FilterQueryOp.Equals, x.storageGroupId)
            .or(new Predicate('storageGroup.parentStorageGroupId', FilterQueryOp.Equals, x.storageGroupId))
            .or(new Predicate('storageGroup.parentStorageGroup.parentStorageGroupId', FilterQueryOp.Equals, x.storageGroupId))
            .or(new Predicate('storageGroup.parentStorageGroup.parentStorageGroup.parentStorageGroupId', FilterQueryOp.Equals, x.storageGroupId))
        });
        return Predicate.or(predicatesPerStorageGroup);
      }
      return null;
    }

    this.locationService = new ServiceBase<Zeus.Web.Model.Location>(Constants.EntityTypeNames.Location);
    this.locationService.gridDataSource.customSelect2Predicates = () => {
      if (this.newArticleLocation?.storageId != null) {
        let predicates: Predicate[] = [
          new Predicate('storageId', FilterQueryOp.Equals, this.newArticleLocation.storageId),
          ...this.article.articleLocations.map(artLoc => new Predicate('id', FilterQueryOp.NotEquals, artLoc.locationId))
        ];

        // Limit locations to the volumes configured in the article
        let volumeIds = this.article.articleVolumeConfigs.map(x => x.storageVolumeId);
        let volumePredicate = volumeIds.length > 0 ? Predicate.or(volumeIds.map(x =>
          new Predicate('defaultStorageVolumeId', FilterQueryOp.Equals, x)
            .or(new Predicate('alternateLocationVolumes', FilterQueryOp.Any, 'storageVolumeId', FilterQueryOp.Equals, x))
        )) : null;
        if (volumePredicate != null) {
          predicates.push(volumePredicate);
        }

        // Limit locations to location type configured in the article
        let defaultAsg = this.article.articleStorageGroups?.find(x => x.default);
        let asg: Zeus.Web.Model.ArticleStorageGroup =
          this.article.articleStorageGroups.find(x => x.storageGroupId == this.newArticleLocation.storage.storageGroupId)
          ?? this.article.articleStorageGroups.find(x => x.storageGroupId == this.newArticleLocation.storage.storageGroup.parentStorageGroupId)
          ?? this.article.articleStorageGroups.find(x => x.storageGroupId == this.newArticleLocation.storage.storageGroup.parentStorageGroup.parentStorageGroupId)
          ?? this.article.articleStorageGroups.find(x => x.storageGroupId == this.newArticleLocation.storage.storageGroup.parentStorageGroup.parentStorageGroup.parentStorageGroupId)
          ?? defaultAsg;
        if (asg) {
          let storageLocationTypeIds = [1, 2, 3].map(i => asg[`storageLocationType${i}Id`]).filter(x => x);
          if (storageLocationTypeIds.length > 0) {
            predicates.push(Predicate.or(storageLocationTypeIds.map(x => new Predicate("storageLocationTypeId", FilterQueryOp.Equals, x))));
          } else {
            predicates.push(new Predicate("storageLocationTypeId", FilterQueryOp.Equals, null));
          }
        }

        return Predicate.and(predicates);
      } else {
        return null;
      }
    };
    this.locationService.gridDataSource.customSelect2QueryParameters = () => {
      return { getAvailableLocationForArticle: true };
    }
  }

  private async attached() {
    await this.articleLocationService.getEntities(
      new Predicate('articleId', FilterQueryOp.Equals, this.article.id),
      ['storage.bays', 'location.trayContainer']
    );

    for (let location of this.article.articleLocations) {
      this.fetchStorageGroupOnUserSite(location.storage.storageGroupId);
      this.setTheoreticalQuantity(location);
    }

    this.articleLocations.push(...this.article.articleLocations);
  }

  @computedFrom('authService.currentUser.id')
  private get canCallTray(): boolean {
    return this.authService.checkAccess("tray", Constants.Permissions.Consult);
  }

  private fetchStorageGroupOnUserSite(storageGroupId: number) {
    this.apiService.storageGroupOnUserSite(storageGroupId).then(onSite => this.locationsOnSameSite[storageGroupId] = onSite)
  }

  private setTheoreticalQuantity(articleLocation: Zeus.Web.Model.ArticleLocation) {
    (articleLocation as any).theoreticalQuantity = Math.max(articleLocation.currentQuantityInLocation + articleLocation.reservedInputQuantity - articleLocation.reservedPickingQuantity, 0);
  }

  private addLocation() {
    if (this.newArticleLocation != null) {
      return;
    }
    this.newArticleLocation = this.articleLocationService.createEntity({ currentQuantityInLocation: 0 }, true);
    this.articleLocations.push(this.newArticleLocation);
    this.otherArticleLocations?.splice(0);

    this.disposables.push(
      this.bindingEngine.propertyObserver(this.newArticleLocation, "storageId").subscribe((newValue, oldValue) => {
        if (newValue != null && newValue != oldValue) {
          this.newArticleLocation.locationId = null;
          this.locationService.gridDataSource.queryParameters = {};
        }
      }),
      this.bindingEngine.propertyObserver(this.newArticleLocation, "locationId").subscribe(async (newValue, oldValue) => {
        if (newValue != null && newValue != oldValue) {
          await this.articleLocationService.getEntities(new Predicate("locationId", FilterQueryOp.Equals, newValue), ['article']);

          this.otherArticleLocations = this.newArticleLocation.location.articleLocations.filter(al => al.id >= 0);
          if (this.otherArticleLocations.length > 0) {
            await this.box.showQuestion(this.i18n.tr('articlelocation.alreadyLinked', { articles: this.otherArticleLocations.map(al => al.article.reference).join(" ; ") }),
              this.i18n.tr('menu.del-filter-set-title'),
              [
                { label: this.i18n.tr('general.no'), theme: 'primary', type: 'ghost', fn: (dialogBox?: DialogBoxViewModel) => dialogBox.controller.ok(false) },
                { label: this.i18n.tr('general.yes'), theme: 'warning', type: 'solid', fn: (dialogBox?: DialogBoxViewModel) => dialogBox.controller.ok(true) }
              ]
            ).whenClosed(async result => {
              if (result.output) {
                this.otherArticleLocations.forEach(al => al.entityAspect.setDeleted());
              }
              else {
                this.newArticleLocation.locationId = null;
              }
            });
          }
        }
      })
    );
  }

  @computedFrom('newArticleLocation.storageId', 'newArticleLocation.locationId')
  private get canSaveNewLocation() {
    return this.newArticleLocation?.storageId != null && this.newArticleLocation?.locationId != null;
  }

  private saveNewLocation() {
    if (false == this.canSaveNewLocation) {
      return;
    }
    this.articleLocations.splice(-1);
    let newLocation = this.prepareSaveNewLocation();
    this.articleLocationService.saveEntity(newLocation)
      .catch(() => {
        newLocation.articleId = null;
        this.newArticleLocation = newLocation;
      })
      .finally(() => this.articleLocations.push(newLocation));
    if (this.otherArticleLocations?.length > 0) {
      this.articleLocationService.saveEntities(this.otherArticleLocations, true);
    }
  }
  private prepareSaveNewLocation() {
    let newLocation = this.newArticleLocation;
    this.newArticleLocation = null;
    newLocation.articleId = this.article.id;
    this.setTheoreticalQuantity(newLocation);
    return newLocation;
  }

  private canRemoveLocation(articleLocation: Zeus.Web.Model.ArticleLocation): boolean {
    return articleLocation.currentQuantityInLocation == 0 && articleLocation.reservedPickingQuantity == 0 && articleLocation.reservedInputQuantity == 0;
  }

  private async removeLocation(articleLocation: Zeus.Web.Model.ArticleLocation) {
    if (articleLocation == this.newArticleLocation) { //articleLocation.entityAspect.entityState.isAdded()) {
      this.newArticleLocation.entityAspect.setDeleted();
      this.removeFromArticleLocationList(this.newArticleLocation);
      this.newArticleLocation = null;
    } else if (this.canRemoveLocation(articleLocation)) {
      await this.articleLocationService.deleteEntities([articleLocation], true);
      this.removeFromArticleLocationList(articleLocation);
    } else {
      toastr.error(this.i18n.tr('articlelocation.removeLocationWithQuantityError'));
    }
  }

  private removeFromArticleLocationList(articleLocation: Zeus.Web.Model.ArticleLocation) {
    this.articleLocations.splice(this.articleLocations.findIndex(x => x == articleLocation), 1);
  }

  private isLocationStatic(location: Zeus.Web.Model.ArticleLocation) {
    return location.location?.storage?.storageTypeId === Constants.StorageTypes.Static;
  }

  private async moveTrayToBay(articleLocation: Zeus.Web.Model.ArticleLocation) {
    if (articleLocation?.location?.trayContainer?.trayId != null && articleLocation?.storage?.dynamicStorageId != null) {
      try {
        if (articleLocation.storage.bays?.length > 1) {
          await this.dialogService.open({ viewModel: MoveTrayToBay, model: { storage: articleLocation.storage, trayId: articleLocation.location.trayContainer.trayId }, lock: false });
        } else {
          const dto: MoveTrayToBayDto = {
            storageId: articleLocation.storageId,
            trayId: articleLocation.location.trayContainer.trayId,
            bayNumber: 1
          };
          await this.apiService.moveTrayToBay(dto);
          this.logger.Log(this.i18n.tr("traycontainer.calledTray"), null, null, true);
        }
      } catch (e) {
        if (typeof e == "string") {
          this.logger.LogError(this.i18n.tr("general.errorTitle"), e, 'ArticleDetailLocations', true);
        } else {
          this.logger.LogError(this.i18n.tr("general.errorTitle"), e.error, 'ArticleDetailLocations', true);
        }
      };
    }
  }

  public beforeSaveArticle() {
    if (this.newArticleLocation != null) {
      if (this.canSaveNewLocation) {
        this.prepareSaveNewLocation();
      } else {
        this.removeLocation(this.newArticleLocation);
      }
    }
  }
}
