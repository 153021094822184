import { HttpClient } from 'aurelia-fetch-client';
import { Router } from 'aurelia-router';
import { EntityDetailViewModelBase, CustomLogger, EditingModeEnum, ServiceBase, Actions } from 'digiwall-lib';
import { autoinject, BindingEngine, computedFrom } from 'aurelia-framework';
import * as Constants from '../constants';
import { Zeus } from 'generated';
import { Endpoints } from 'endpoints';
import { AppModuleService } from 'app-modules/app-module-service';
import { AppModuleEnum } from 'app-modules/constants';
import { ValidationRules } from 'aurelia-validation';

@autoinject
export class HardwareParametersDetail extends EntityDetailViewModelBase<Zeus.Web.Model.HardwareParameters> {
  public ressourceName: string = Constants.EntityTypeNames.HardwareParameters;
  public headerText: string = this.i18n.tr("hardwareparameters.hardwareparameters");

  constructor(router: Router, logger: CustomLogger, private bindingEngine: BindingEngine, private httpClient: HttpClient,
    private appModuleService: AppModuleService) {
    super(router, logger);
    super.initialize(new ServiceBase<Zeus.Web.Model.HardwareParameters>(Constants.EntityTypeNames.HardwareParameters));
  }

  public get documentTitle() {
    return "";
  }

  public async activate() {
    this.editingMode = EditingModeEnum.Update;
    this.entity = await this.service.firstEntity();
    this.controller.addObject(this.entity);

    if (this.isConveyorActive) {
      ValidationRules
        .ensure("maxBinWeightForPicking")
        .satisfies((val, obj) => this.entity.maxBinWeightForPicking > 0)
        .when(x => this.entity.applyMaxWeightPerBinOnAutolaunch)
        .withMessage("hardwareparameters.applyMaxWeightPerBinOnAutolaunchError")
        .on(this.entity);
    }
  }

  private originalValues: Zeus.Web.Model.HardwareParameters;
  protected beforeSave(): Promise<boolean | void> {
    this.originalValues = this.entity?.entityAspect?.originalValues as Zeus.Web.Model.HardwareParameters;
    return super.beforeSave();
  }

  //#region Conveyor
  @computedFrom('appModuleService._activeModules')
  private get isConveyorActive(): boolean {
    return this.appModuleService.isActive(AppModuleEnum.Conveyor)
  }
  //#enregion
}
